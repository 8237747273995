<template>
    <div id="home">
      <div>
        <div class="big_box">
          <div class="small_txt">
            <p>
              自适应教育是一种教育方法，其中教育技术和工具使用人工智能（AI）和机器学习技术来个性化地满足每个学生的学习需求和能力水平。
            </p>
            <p>这意味着教育系统能够根据每个学生的特定需求和学习进度，</p>
            <p>自动调整学习内容、教学方法和学习节奏，以提供最合适的学习体验。</p>
            <p>自适应教育的主要特征包括：</p>
            <p>
              个性化学习路径：教育系统根据每个学生的学习能力和需求创建独特的学习路径。一些学生可能需要更多的挑战，而其他学生可能需要更多的支持。
            </p>
            <p>
              实时反馈：系统能够提供即时反馈，帮助学生了解他们的学习进度和弱点，以便他们可以及时纠正错误或调整学习策略。
            </p>
            <p>
              自主学习：自适应教育鼓励学生自主选择学习材料和方法，以培养他们的自主学习能力。学生可以根据自己的兴趣和学习风格来定制自己的学习体验。
            </p>
            <p>
              数据驱动的个性化：教育系统依赖学生的学习数据来调整教学方法。这包括学生的学习进度、答题历史、兴趣和反馈等信息。
            </p>
            <p>
              自适应教育的目标是提高学习效率，减少学习障碍，促进学生的自主学习，从而更好地满足每个学生的教育需求。这种方法通常在在线教育平台、
            </p>
            <p>
              教育软件和学习管理系统中得到应用，以帮助教育者更好地个性化教学，提高学生的学术成就。
            </p>
          </div>
          <div class="small_bigtxt">扫码咨询</div>
          <div class="code_pic">
              <img src="../assets/firstpage/扫码咨询.png" alt="" class="image_code">
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style scoped>
  .big_box {
    height: 10rem;
    background-image: url("../assets/firstpage/其他背景图2.png");
    background-position: center center;
    /* 背景图不平铺 */
    background-repeat: no-repeat;
    /* 当内容高度大于图片高度时，背景图像的位置相对于viewport固定 */
  
    /* 让背景图基于容器大小伸缩 */
    background-size: cover;
    position: relative;
  }
  .small_txt {
    position: absolute;
    top: 2rem;
    font-size: 16px;
    left: 400px;
    box-sizing: border-box;
  }
  .small_bigtxt {
    position: absolute;
    box-sizing: border-box;
    left: 400px;
    top: 7.5rem;
    color: #ffffff;
    font-size: 20px;
  }
  .code_pic{
      position: absolute;
      box-sizing: border-box;
      top: 7rem;
      left: 700px;
      
  }
  .image_code{
      height: 150px;
  }
  </style>